import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Responsive } from "./responsive";
gsap.registerPlugin(ScrollToPlugin);

export default function Button() {
    try {
        Btn_header_logo();
        Btn_header_more();
        Btn_menu_expand();
        Btn_intro();
    } catch (error) {}
}

function Btn_header_logo() {
    const btn = document.querySelector("#header .navbar-title-text");

    btn.addEventListener('click', (e) => {
        e.preventDefault();
        gsap.to(window, {
            duration: 1,
            ease: "power4.out",
            scrollTo: {
                y: 0
            }
        });
    });
}

function Btn_header_more() {
    const btn = document.querySelector("#header .navbar-menu-more");

    const tl_btn = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.6,
            ease: "power4.out"
        }
    });

    tl_btn.to(".navbar-icon-base", {
        fill: "#FFFFFF",
    });

    tl_btn.to(".navbar-icon-dot", {
        fill: "#000000",
    }, "-=0.6");

    btn.addEventListener('mouseenter', (e) => {
        tl_btn.play();
    });

    btn.addEventListener('mouseleave', (e) => {
        tl_btn.reverse();
    });
}

function Btn_menu_expand() {
    let open = false;
    const btn = document.querySelector("#menu .menu-expand");

    const tl_btn = gsap.timeline({
        paused: true,
        defaults: {
            duration: 1.2,
            ease: "power4.inOut"
        }
    });
    
    Responsive(() => {
        tl_btn.to(".menu-expand", {
            padding: "1.5rem 0.8rem"
        });
    
        tl_btn.to(".menu-sticky", {
            opacity: 0,
            duration: 0.3,
            ease: "none"
        }, 0);
    
        tl_btn.to(".menu-expand .text", {
            opacity: 0
        }, 0);
    
        tl_btn.to(".menu-expand .close", {
            display: "block",
            opacity: 1
        }, 0);
    
        tl_btn.to(".menu-wrap", {
            x: 0,
        }, 0);
    
        tl_btn.to(".menu-stack", {
            opacity: 1,
            scale: 1,
            x: 0,
        }, 0);
    
        tl_btn.to(".menu-client, .menu-services-1", {
            opacity: 0.2,
        }, 0);
    }, () => {
        tl_btn.set(".menu-stack", {
            display: "block",
        }, 0);

        tl_btn.to(".menu-expand", {
            opacity: 0,
            y: -40,
            visibility: "hidden",
            ease: "expo.out"
        }, 0);
    
        tl_btn.to(".menu-stack", {
            opacity: 1,
            y: 0,
        }, 0);

    });
    
    btn.addEventListener('click', (e) => {
        if (open === false) {
            tl_btn.play();
            open = true;
        } else {
            tl_btn.reverse();
            open = false;
        }

    });
}

function Btn_intro() {
    const btn = document.querySelector("#intro .btn");

    const tl_btn = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.6,
            ease: "power4.inOut"
        }
    });

    tl_btn.to(".btn-icon-arrow", {
        opacity: 0,
        display: "none",
        ease: "power4.out"
    });

    tl_btn.to(".btn-icon-circle", {
        scale: 0.5,
        transformOrigin: "center"
    }, "-=0.8");

    btn.addEventListener('mouseenter', (e) => {
        tl_btn.play();
    });

    btn.addEventListener('mouseleave', (e) => {
        tl_btn.reverse();
    });

    btn.addEventListener('click', (e) => {
        e.preventDefault();

        gsap.to(window, {
            duration: 1,
            ease: "power4.out",
            scrollTo: {
                y: "#about",
                offsetY: 200
            }
        });
    });
}