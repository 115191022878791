import gsap from "gsap";
import {
    ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function Responsive(_desktop, _mobile) {
    try {
        ScrollTrigger.matchMedia({
            // desktop
            "(min-width: 990px)": function() {
                _desktop();

            },

            // mobile
            "(max-width: 991px)": function() {
                _mobile();
            },

        });
    } catch (error) {}
}

export function Responsive_desktop(_desktop) {
    try {
        ScrollTrigger.matchMedia({
            // desktop
            "(min-width: 990px)": function() {
                _desktop();

            },
        });
    } catch (error) {}
}

export function Responsive_mobile(_mobile) {
    try {
        ScrollTrigger.matchMedia({
            // mobile
            "(max-width: 991px)": function() {
                _mobile();
            },

        });
    } catch (error) {}
}

export function Responsive_nomobile(_callback) {
    try {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            _callback();
        }
    } catch (error) {}
}