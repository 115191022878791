import gsap from "gsap";
import {
    ScrollTrigger
} from "gsap/ScrollTrigger";
import { Responsive, Responsive_desktop } from "./responsive";
gsap.registerPlugin(ScrollTrigger);

export default function Navbar() {
    try {

        Avail();
        Menu();

        Responsive_desktop(() => {
            ScrollSection();
        });

    } catch (error) {}

}

function Avail() {
    let open = false;
    const menu = document.querySelector("#header .navbar-menu-avail");

    const tl_menu = gsap.timeline({
        paused: true,
        defaults: {
            duration: 1,
            ease: "expo.inOut"
        }
    });

    tl_menu.to(".navbar-icon-arrow", {
        rotate: 180,
        transformOrigin: "center"
    });

    tl_menu.to(".navbar-menu-avail-open", {
        opacity: 1,
        display: "block",
        y: 0,
    }, 0);

    tl_menu.to(".navbar-menu-avail-open-shapes .active", {
        fill: "#eac181"
    }, 0);

    tl_menu.to(".navbar-menu-avail-open-shapes .circle", {
        xPercent: 20
    }, 0);

    Responsive(() => {
        menu.addEventListener("mouseenter", (e) => {
            e.preventDefault();
            tl_menu.play();
            open = true;
        });

        menu.addEventListener('mouseleave', (e) => {
            tl_menu.reverse();
            open = false;
        });
    }, () => {
        menu.addEventListener("click", (e) => {
            e.preventDefault();

            if (open === false) {
                tl_menu.play();
                open = true;
            } else {
                tl_menu.reverse();
                open = false;
            }
        });
    })
}

function Menu() {
    const menu = document.querySelector("#header .navbar-menu-more");

    const tl_menu = gsap.timeline({
        paused: true,
        defaults: {
            ease: "power4.inOut"
        }
    });

    tl_menu.to("#main, #header, #footer", {
        xPercent: -100,
        stagger: 0
    });

    tl_menu.to("#menu", {
        x: 0,
        display: "block"
    }, 0);

    const tl_menu_open = gsap.timeline({
        paused: true,
        defaults: {
            ease: "power4.out"
        }
    });

    menu.addEventListener("click", (e) => {
        e.preventDefault();

        gsap.set("body", {
            overflowY: "hidden"
        });

        tl_menu.play();
        tl_menu_open.timeScale(1).play();

        Responsive(() => {
            gsap.to(".menu-sticky", {
                delay: 1,
                x: 0,
                opacity: 1,
                duration: 0.8,
                display: "block",
                onComplete: ()  => {
                    gsap.set(".menu-sticky", {
                        pointerEvents: "auto"
                    });
                }
            });
        }, () => {
            gsap.to(".menu-sticky", {
                delay: 1,
                opacity: 1,
                duration: 0.8,
                display: "block",
                onComplete: ()  => {
                    gsap.set(".menu-sticky", {
                        pointerEvents: "auto"
                    });
                }
            });
        });
    });

    gsap.utils.toArray(".menu-nav").forEach((el, i) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();

            gsap.set("body", {
                overflowY: "scroll"
            });
    
            tl_menu.reverse();
            tl_menu_open.timeScale(2).reverse();
    
            gsap.to(".menu-sticky", {
                opacity: 0,
                display: "none",
                duration: 0.2,
                ease: "power1.out",
                overwrite: "all",
                onComplete: ()  => {
                    Responsive(() => {
                        gsap.set(".menu-sticky", {
                            x: -100,
                            pointerEvents: "none"
                        });
                    }, () => {
                        gsap.set(".menu-sticky", {
                            pointerEvents: "none"
                        });
                    });
                }
            });
        });
    });
}

function ScrollSection() {
    const menu = document.querySelector("#menu");
    const menuclient = document.querySelector(".menu-client");

    let scroll = 0;

    menu.addEventListener('wheel', (e) => {
        if (e.deltaY < 0) {
            if (scroll < 0) {
                let scrollMove = scroll+=2.5;

                gsap.to(menuclient, {
                    yPercent: scrollMove,
                    duration: 0.5,
                    ease: "expo.out"
                });   
            }
        } else if (e.deltaY > 0) {
            if (scroll > -50) {
                let scrollMove = scroll-=2.5;
                gsap.to(menuclient, {
                    yPercent: scrollMove,
                    duration: 0.5,
                    ease: "expo.out"
                });
            }
        }

        console.log(`Scroll: ${scroll}`);
    })
}