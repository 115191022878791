import 'regenerator-runtime/runtime';
import gsap from "gsap";
import { loading_start, loading_end, Splittext } from "./config/animation";
import Imgloaded from "./imgloaded";
import { preloadImages } from "./plugin/preload";
import FontFaceObserver from "fontfaceobserver";

export default async function Loading(_callback) {
    let elMain = document.querySelector("#main");
    let elLoading = document.querySelector("#loading");
    
    const font1 = new FontFaceObserver('Ogg Text');
    const font2 = new FontFaceObserver('GT Flexa Condensed');
    const font3 = new FontFaceObserver('Sporting Grotesque');
    const font4 = new FontFaceObserver('Roslindale');
    // const fonts = [font1.load(), font2.load(), font3.load(), font4.load()];

    
    const tl_loadcomplete = gsap.timeline();

    return Promise.all([font1.load(), font2.load(), font3.load(), font4.load(), preloadImages(elMain)]).then(() => {
        Splittext()
        tl_loadcomplete.to(elLoading, loading_end);
        _callback();
    });
}

export function Loading_enter(_callback) {
    Splittext();
    Imgloaded();

    let elLoading = document.querySelector("#loading");
    const tl_loadcomplete_enter = gsap.timeline({
        delay: 0.1,
        onComplete: _callback()
    });

    tl_loadcomplete_enter.to(elLoading, loading_end);
}

export function pageTransition() {
    const tl_transition = gsap.timeline();

    tl_transition.to("#loading", loading_start, "-=0.4");
}