// Dependency
import Loading, { Loading_enter } from "./loading";
// import Locomotive, { locoScroll } from './locomotive';
import Default from './config/default';

import Navbar from "./navbar";
import Universal from "./universalanim";
import Credit from "./credit";
import Home from "./home";
import Button from "./button";

function Init() {
    Navbar();
    Button();
    Home();
    Universal();
    // locoScroll.update();
}

function First() {
    Default();
    // Locomotive();
    Navbar();

    Loading(() => {
        Init();
    });
}

First();

Credit();