import gsap from "gsap";
import lottie from "lottie-web";

export default function Home() {
    try {
        const lottieel = document.querySelector(".intro-radial-anim");
        lottie.loadAnimation({
            container: lottieel,
            path: '/space.json',
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        const tl_home = gsap.timeline({
            delay: 0.5,
        });

        tl_home.to(".intro-logo", {
            opacity: 1
        });

        tl_home.to(".intro-radial-anim", {
            opacity: 1,
            scale: 1,
            rotation: 0,
            duration: 2.0,
            ease: "sine.out"
        }, 0);

        tl_home.to(".intro-title .line", {
            y: 0,
            stagger: 0.2
        }, 0);

        tl_home.to(".intro-btn", {
            opacity: 1,
            duration: 0.8,
            ease: "expo.in"
        }, 0);
    } catch (error) {
        console.log(error);
    }
}