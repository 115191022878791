import imagesLoaded from "imagesloaded";
import gsap from "gsap";
import {
    ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Imgloaded() {
    let elMain = document.querySelector("#main");
    const imgLoad = imagesLoaded(elMain, {
        background: true
    });
    imgLoad.on("always", () => {
        ScrollTrigger.refresh();
    });
}